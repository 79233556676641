/* http://meyerweb.com/eric/tools/css/reset/
    v4.0 | 20180602
    License: none (public domain)
  */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
}
input,
button,
textarea,
select {
  font: inherit;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* http://www.paulirish.com/2012/box-sizing-border-box-ftw/ (2015/04/28)*/
html {
  box-sizing: border-box;

  &.no-scroll {
    overflow: hidden;
  }
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
/* Additional resets */
a {
  text-decoration: none;
  color: inherit;
}
button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  text-align: inherit;
  outline: none;
  line-height: inherit;
  -webkit-appearance: none;
}
input {
  appearance: none;
  font: inherit;
  outline: none;
}
textarea {
  font: inherit;
  outline: none;
}
img {
  user-select: none;
}
/* Fix antialiasing */
*,
*:before,
*:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Default styles */
body {
  overflow-x: hidden;
  font-family: var(--ui-font-family-body);
  font-weight: var(--ui-font-weight-medium);
  font-size: var(--ui-font-body-size-md);
  line-height: var(--ui-font-body-line-height-md);
  color: var(--ui-colors-grey-dark);
}

::selection {
  color: var(--ui-colors-white);
  background-color: var(--ui-colors-primary-default);
}

h1,
h2,
h3,
h4 {
  font-family: var(--ui-font-family-heading);
}

sup {
  vertical-align: super;
  font-size: smaller;
  align-self: end;
}
sub {
  vertical-align: sub;
  font-size: smaller;
  align-self: start;
}

/*
  * Progress bar
  */
#nprogress .bar {
  height: 4px;
  background: var(--ui-colors-primary-default);
  position: fixed;

  .peg {
    box-shadow:
      0 0 10px var(--ui-colors-primary-default),
      0 0 5px var(--ui-colors-primary-default);
  }
}

/*
  * Offset anchors from sticky header
  */
:root {
  --header-height: 82px;
  --top-padding: 70px;
  --banner-height: 0px;

  /* TODO ADD POSTCSS AND TOKENS */
  @media (max-width: 991px) {
    --header-height: 60px;
  }
}

[id] {
  scroll-margin-top: calc(var(--header-height) + var(--top-padding));
}

/* Enzuzo Cookie Banner */
.ez-consent {
  --ez-preferences-switch-on: var(--ui-colors-primary-default) !important;

  .notification-main.enzuzo-shadow {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2) !important;
  }

  .notification-button,
  button.enzuzo-modal-button {
    border-radius: 100px !important;
    border-width: 1px !important;
    font-family: var(--ui-font-family-heading) !important;
    font-size: var(--ui-font-body-size-sm) !important;
    line-height: 1.25rem;
    padding: 10px var(--ui-spacings-md) !important;

    &:hover {
      background-color: var(--ui-colors-grey-default) !important;
      color: var(--ui-colors-white) !important;
      border-color: var(--ui-colors-grey-default) !important;
    }
  }

  .notification-main .notification-buttons {
    flex-direction: column-reverse;
    padding-top: var(--ui-spacings-sm);
  }

  .cookieButtonWrap {
    flex-direction: row !important;
  }

  #notificationManagerLink {
    margin: 0 !important;
    white-space: nowrap;
  }

  .enzuzo-poweredby-wrap {
    display: none !important;
  }
}

/* Enzuzo Cookie Banner */
.ez-consent {
  --ez-banner-link-color: var(--ui-colors-grey-dark) !important;
  --ez-preferences-switch-on: var(--ui-colors-primary-default) !important;
  --ez-banner-decline-background-color: var(--ui-colors-white) !important;
  --ez-banner-decline-background-color-hover: var(--ui-colors-grey-default) !important;
  --ez-banner-decline-text-color: var(--ui-colors-grey-dark) !important;
  --ez-banner-decline-border-color: var(--ui-colors-grey-dark) !important;
  --ez-banner-allow-all-background-color-hover: var(--ui-colors-grey-default) !important;
  --ez-preferences-allow-selected-background-color-hover: var(--ui-colors-grey-default) !important;

  .notification-main {
    border-radius: 4px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2) !important;
    flex-direction: column !important;
    max-width: 380px !important;
    padding: 32px !important;
    left: auto !important;
    right: 20px !important;
    bottom: 20px !important;
    transform: none !important;
    width: calc(100% - 64px) !important;

    .notification-text-medium {
      font-size: var(--ui-font-body-size-sm) !important;
    }

    .notification-desc {
      p:last-of-type {
        display: inline !important;
      }

      #notificationPolicyLink {
        display: inline !important;
      }
    }

    .notification-buttons {
      flex-direction: column-reverse;
      padding-top: var(--ui-spacings-sm);
    }

    .closeTrigger {
      padding-right: 0 !important;
    }
  }

  .notification-button,
  button.enzuzo-modal-button,
  button.button-default,
  button.button-rounded,
  .notification-main button.button-default,
  .notification-main button.button-rounded {
    border-radius: 100px !important;
    border-width: 1px !important;
    font-family: var(--ui-font-family-heading) !important;
    font-size: var(--ui-font-body-size-sm) !important;
    line-height: 1.25rem;
    padding: 10px var(--ui-spacings-md) !important;
    transition: all 80ms ease-in-out !important;

    &:hover {
      color: var(--ui-colors-white) !important;
      border-color: var(--ez-banner-allow-all-background-color-hover) !important;
    }
  }

  .cookieButtonWrap {
    flex-direction: row !important;
  }

  #notificationManagerLink {
    margin: 0 !important;
    white-space: nowrap;
  }

  .enzuzo-close-banner-button,
  .enzuzo-poweredby-wrap {
    display: none !important;
  }
}
